import React from 'react'

const Loader = () => {
  return (
    <div className="wifi-loader-container">
        <div class="landpage-loader" >
          <span>MULTICHAINFINACE</span>
          <span>MULTICHAINFINACE</span>
      </div>
    </div>
  )
}

export default Loader